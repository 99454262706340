.filled-circle-sequence {
  width: 67px;
  height: 67px;
  animation: moveXFilledCircle 1s steps(10) infinite;
}

@keyframes moveXFilledCircle {
  from {
    background-position-y: 670px;
  }
  to {
    background-position-y: 0px;
  }
}
