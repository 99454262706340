.circle_sequence {
  width: 64px;
  height: 64px;
  animation: moveXCircle 2s steps(20) infinite;
}

@keyframes moveXCircle {
  from {
    background-position-y: 1280px;
  }
  to {
    background-position-y: 0px;
  }
}
